@import '../../../styles/hclayout';

.HelpCenterIndex{
    box-sizing: border-box;
    width: $indexWidth;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.05);
    padding: 0em 1.2em;
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: left;
    padding-top: 6em;
    display: flex;
    // justify-content: right;
    margin-bottom: 2em;
    position: fixed;
    // right: auto;

    &.hide{
        display: none;
    }
}

.mainFolderAccordion{
    margin-bottom: 2em;
    width: 100%;
}

.gigauser-dark{
    .HelpCenterIndex{
        border-right: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
    }
}