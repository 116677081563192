.gigauser-Breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 0.8em;
}

.gigauser-PageTitle-pathSegment {
  display: inline-block;
  border-radius: 999px;
  transition: 0.3s;
  padding: 4px 8px;
  color: #727272;
}
.gigauser-PageTitle-pathSegment.gigauser-home-segment {
  margin-left: -8px;
}
.gigauser-PageTitle-pathSegment:hover {
  cursor: pointer;
  color: var(--gigauser-brand-color);
  background-color: #f2f2f7;
}
.gigauser-PageTitle-pathSegment.onDrop {
  color: black;
  background-color: #665aef;
  padding: 0px 10px;
  border-radius: 3px;
}
.gigauser-PageTitle-pathSegment.gigauser-activeSegment {
  background-color: #f2f2f7;
  color: var(--gigauser-brand-color);
  font-weight: bold;
}

.gigauser-breadcrumbs-home {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.gigauser-Breadcrumb-arrow {
  transform: rotate(270deg);
  width: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  transition: 0.3s;
}

.droppable-pathSegment {
  cursor: pointer;
}
.droppable-pathSegment:hover {
  color: #665aef;
}

.gigauser-dark .gigauser-PageTitle-pathSegment {
  color: #bfc7d4;
}
.gigauser-dark .gigauser-PageTitle-pathSegment:hover {
  background-color: #4b4b4b;
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .gigauser-PageTitle-pathSegment.gigauser-activeSegment {
  background-color: #303030;
  color: var(--gigauser-darkMode-brand-color);
}/*# sourceMappingURL=Breadcrumbs.css.map */