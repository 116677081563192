.gigauser-lds-ring-tour {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .gigauser-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: gigauser-lds-ring-tour 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .gigauser-lds-ring-tour div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .gigauser-lds-ring-tour div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .gigauser-lds-ring-tour div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes gigauser-lds-ring-tour {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .gigauser-start-tour{
    background-color: var(--gigauser-brand-color);
    border: none;
    border-radius: 3px;
    color: white;
    padding: 5px 10px;
    transition: 0.3s;
    cursor: pointer;
}