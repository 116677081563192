.gigauser-guideslist-container {
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}
.gigauser-guideslist-container.gigauser-mobile {
  max-width: 450px;
}/*# sourceMappingURL=ContentNavigator.css.map */