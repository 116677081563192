$pink: #d43f8c;
$lightPink: #F2C5DD;
$lightestPink: #FBECF3;

$darkModeBg: rgb(15, 17, 23) ;
$darkModeTextColor: #bfc7d4;


$lightenValue: 0.15;

$brandColor: #307D53;
$brandColor: $pink;
$lightBrandColor: lighten($color: $brandColor, $amount: 5.0);
$lighterBrandColor: lighten($color: $brandColor, $amount: 40.0);

//Greys
$hoverGrey: rgba(200, 200, 200, 0.05) ;
$darkestGrey: #303030 ;
$darkerGrey: #4b4b4b ;
$darkGrey: #727272;
$clipboardGrey: #f2f2f7;
$mediumGrey: #c7c7c7 ;
$lightGrey: #f0f3ff;
$offWhite: #f5f7fc;
$inputGrey: #D9D9D9;
$inputGrey: #c2c1c1;
$headerGrey: #EEF0F2;
$hoverTipGrey: #282828;


//Reds
$darkModeRed:#ffa69e;
$lightModeRed:#db5461;

//Greens
$darkModeGreen:#59ffa0;
$lightModeGreen:#3a9434;

//Oranges
$lightModeOrange: #ff965e;
$darkGold: #d89f03;
$lightGold: #FFFBEC;


//Others
$darkPurple: #665aef;
$lightPurple: #e0e7ff;
$indigo200: #c7d2fe;
$indigo700: #4338ca;



//White
$white: #ffffff;

//comments Background
$commentsBG: #ADDDFF;

//Filters
$filter_lightModeRed: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
$filter_white: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
$filter_lightModeGreen: invert(44%) sepia(70%) saturate(481%) hue-rotate(68deg) brightness(93%) contrast(85%);

$filter_mediumPurple: invert(81%) sepia(8%) saturate(2398%) hue-rotate(198deg) brightness(101%) contrast(98%);
$filter_darkPurple: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);

$filter_darkGrey: invert(46%) sepia(1%) saturate(0%) hue-rotate(208deg) brightness(96%) contrast(94%);

