
@import '../../../../styles/hclayout';

.HelpCenterViewer{

    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: $indexWidth;

    &.remove-margin{
        margin-left: 0;
        justify-content: center;
    }
    
    .ViewerContainer{
        height: 100%;
        padding: 0.5em 3em;
        box-sizing: border-box; /* Padding and border are included within the width */
        width: 800px;
        padding-top: 6em;

        &.CollectionViewer{
            width: 1000px;
        }

    }   

    .TocContainer{
        width: 15%;
        box-sizing: border-box;
        padding: 2em 0em;
        text-align: left;

        &.hide{
            display: none;
        }
        
    }


}