.gigauser-help-footer {
  font-size: 0.9em;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
  border-top: 1px solid #f0f3ff;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: #f5f7fc;
  margin-top: auto;
  box-shadow: 1px -1px 8px 0px rgba(0, 0, 0, 0.06);
}
.gigauser-help-footer .gigauser-footer-logo {
  display: flex;
}
.gigauser-help-footer .gigauser-footer-logo svg {
  height: 1.5em;
  width: 4.5em;
  margin-left: 10px;
  cursor: pointer;
}

.gigauser-footer-buttons {
  background-color: white;
  display: flex;
  padding: 12px 0px;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.gigauser-footer-buttons .gigauser-footer-tabs {
  width: 40%;
  display: flex;
  font-weight: bold;
  font-size: 1em;
  justify-content: space-between;
  justify-content: space-between;
}
.gigauser-footer-buttons .gigauser-footer-tab-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 6px;
}
.gigauser-footer-buttons .gigauser-footer-tab {
  transition: 0.3s;
}
.gigauser-footer-buttons .gigauser-footer-tab.gigauser-inactive-footer:hover {
  filter: invert(46%) sepia(1%) saturate(0%) hue-rotate(208deg) brightness(96%) contrast(94%);
}
.gigauser-footer-buttons .gigauser-footer-tab.gigauser-active-footer {
  color: var(--gigauser-brand-color);
}

.gigauser-clueso-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}/*# sourceMappingURL=Footer.css.map */