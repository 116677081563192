@import '../../styles/colors';

.gigauser-guideslist-container {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0;
    width: 100%;
    // border: 1px solid red;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;

    &.gigauser-mobile{
        max-width: 450px;

    }


    
}