
.HelpCenterHomeTopbar{
    background-color: var(--gigauser-brand-color);
    background: linear-gradient(180deg, var(--gigauser-brand-color) 0%, rgba(1, 63, 136, 0.00) 400%);
    padding: 2em 7em;
    color: white;
    text-align: left;

    .HelpCenterHomeTopbar-top{
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: 600;

        .HelpCenterHomeTopbar-top-left{
            display: flex;
            align-items: center;
        }

        .HelpCenterHomeTopbar-top-right{
            display: flex;
            align-items: center;
            gap: 40px;
        }

    }

    .brand-logo{

        height: 2em;
        filter: invert(100%) saturate(0%) brightness(10000) ;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid white;

    }

}

.HelpCenterHomeTopbar-title{
    font-size: 1.6em;
    font-weight: bold;
    margin: 1.4em 0px;
    letter-spacing: 0.5px;

}

.HelpCenterHomeTopbar-search{

    width:100%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.343);
    display: flex;
    margin-bottom: 1.3em;
    align-items: center;

    .searchIcon{
        height: 1.6em;
        width: 1.6em;
        padding: 0px 20px;
        display: flex;
        align-items: center;
    }

    .HelpCenterHomeTopbar-search-input{
        background-color: transparent;
        color: white;
        border: none;
        height: 100%;
        width: 100%;
        padding: 20px 0px;
        font-size: 1.5em;

        

        &::placeholder{
            color: white;
            font-weight: 600;
            transition: 0.2s;
        }

        &:focus{
            outline: none;
            &::placeholder{
                color: transparent;
            }
        }
    }
}