.gigauser-CollectionBox {
  border: 1px solid #c7c7c7;
  padding: 16px;
  border-radius: 10px;
  transition: 0.3s;
  background-color: rgba(242, 242, 247, 0.5);
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns {
  display: flex;
  align-items: center;
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns .gigauser-CollectionBox-folder {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns .gigauser-CollectionBox-text {
  text-align: left;
  width: 80%;
  padding: 0px 14px;
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns .gigauser-CollectionBox-text .gigauser-CollectionBox-title {
  font-weight: bold;
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns .gigauser-CollectionBox-text .gigauser-CollectionBox-description {
  font-size: 0.9em;
  width: 80%;
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns .gigauser-CollectionBox-text div {
  padding: 4px 0px;
}
.gigauser-CollectionBox .gigauser-CollectionBox-columns .gigauser-CollectionBox-arrow {
  height: 20px;
  width: 20px;
  transform: rotate(-90deg);
  transition: 0.3s;
}
.gigauser-CollectionBox.gigauser-CollectionBox-notloading:hover {
  cursor: pointer;
  background-color: rgba(242, 242, 247, 0.5);
  border: 1px solid var(--gigauser-brand-color);
}
.gigauser-CollectionBox.gigauser-CollectionBox-notloading:hover .gigauser-CollectionBox-arrow {
  margin-right: 10px;
}

.gigauser-CollectionBox.gigauser-CollectionBox-loading {
  background-color: white;
}
.gigauser-CollectionBox.gigauser-CollectionBox-loading .gigauser-CollectionBox-title {
  border-radius: 3px;
  margin-bottom: 4px;
  color: transparent;
  background-color: #f0f3ff;
}
.gigauser-CollectionBox.gigauser-CollectionBox-loading .gigauser-CollectionBox-description {
  border-radius: 3px;
  color: transparent;
  background-color: #f0f3ff;
}/*# sourceMappingURL=CollectionBox.css.map */