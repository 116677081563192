@import '../../../styles/colors';

.gigauser-simple-tabs{
    background-color: none;
    display: flex;
    gap: 16px;
    
    .gigauser-simple-tab-icon{
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        margin-right: 10px;

        &.s{
            height: 16px;
            width: 16px;
        }
    }

    .gigauser-simple-tab{
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;

        display: flex;
        align-items: center;
        color: $darkerGrey;
        font-weight: 600;
        transition: 0.3s;

        &:hover{
            color: $darkGrey
        }

        &.gigauser-active-tab{
            color: var(--gigauser-brand-color);
            background-color: $lightGrey;
            font-weight: bold;

        }

        &.gigauser-s{
            font-size: 1.1em;
        }

    }

}