@import '../../../../../../styles/colors';

.gigauser-texteditor-toolbar-blocktype-dropdown {
    position: relative;
    display: inline-flex;
  }
  .gigauser-texteditor-toolbar-blocktype-dropdown-heading{
    background-color: $white;
    padding: 4px 16px;
    width: 54px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $pink;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  .gigauser-texteditor-toolbar-blocktype-dropdown-content {
    display: none;
    position: absolute;
    // min-width: 160px;
    margin-top: 30px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: white;
  }
  .gigauser-texteditor-toolbar-blocktype-dropdown-content-item {
    background-color: white;
    padding: 12px 16px;
    margin: 0;
  }
  .gigauser-texteditor-toolbar-blocktype-dropdown-content-item:hover {
    background-color: $pink;
    color: white;
    cursor: pointer;
  }
//   .gigauser-texteditor-toolbar-blocktype-content * {
//     color: black;
//     text-decoration: none;
//     display: block;
//   }
  
  .gigauser-texteditor-toolbar-blocktype-dropdown:hover .gigauser-texteditor-toolbar-blocktype-dropdown-content {
    display: block;
  }
  