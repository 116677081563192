.gigauser-RichTextToolbar {
  background-color: #f0f3ff;
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
}
.gigauser-RichTextToolbar.gigauser-fix-toolbar {
  position: fixed;
  z-index: 999;
  top: 64px;
}
.gigauser-RichTextToolbar.gigauser-isTop {
  position: fixed;
  top: 60px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.gigauser-RichTextToolbar.gigauser-isTop + .remirror-editor-wrapper {
  padding-top: 40px;
}

.gigauser-special-button {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s;
  font-weight: bold;
}
.gigauser-special-button.gigauser-enhance-button {
  color: #d89f03;
  background-color: #FFFBEC;
}
.gigauser-special-button.gigauser-enhance-button:hover {
  border: 1px solid #d89f03;
}
.gigauser-special-button.gigauser-generate-button {
  color: #d43f8c;
  background-color: #FBECF3;
}
.gigauser-special-button.gigauser-generate-button:hover {
  border: 1px solid #d43f8c;
}
.gigauser-special-button .gigauser-special-button-icon {
  height: 20px;
  width: 20px;
}
.gigauser-special-button .gigauser-special-button-text {
  font-weight: 600;
}

.gigauser-toolbar-special-buttons {
  display: flex;
  gap: 8px;
}

.gigauser-toolbar-regular-buttons {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0px 10px;
}
.gigauser-toolbar-regular-buttons .gigauser-RichTextToolbar-reg-icon {
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 9999px;
}
.gigauser-toolbar-regular-buttons .gigauser-RichTextToolbar-reg-icon:hover {
  background-color: #c7c7c7;
}

.gigauser-enhanceLoadingRing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.voiceIcon-container {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  border: 1px solid #c7c7c7;
  padding: 3px 8px;
  border-radius: 4px;
  gap: 10px;
  cursor: pointer;
}
.voiceIcon-container .gigauser-voice-name {
  max-width: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.voiceIcon-container .gigauser-RichTextToolbar-voice-icon {
  width: 14px;
  height: 14px;
}/*# sourceMappingURL=RichTextToolbar.css.map */