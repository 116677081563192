
@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';

$darkGold: #d89f03;

.gigauser-RichTextToolbar{
    background-color: $lightGrey;
    border: 1px solid #e6e6e6;
    // box-shadow: $lighterBoxShadow;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.gigauser-fix-toolbar{
        position: fixed;
        z-index: 999;
        top: 64px;
    }


    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &.gigauser-isTop{
        position: fixed;
        top: 60px;
        z-index: 999;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      
    }

    /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
    &.gigauser-isTop + .remirror-editor-wrapper {
        padding-top: 40px;
    }

}

.gigauser-special-button{
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 10px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s;
    font-weight: bold;
    &.gigauser-enhance-button{
        color: $darkGold;
        background-color: #FFFBEC;

        &:hover{
            border: 1px solid $darkGold;
        }

    }

    &.gigauser-generate-button{
        color: $pink;
        background-color: $lightestPink;

        &:hover{
            border: 1px solid $pink;
        }

    }

    .gigauser-special-button-icon{
        height: 20px ;
        width: 20px;
    }

    .gigauser-special-button-text{
        font-weight: 600;
    }

    
}

.gigauser-toolbar-special-buttons{
    display: flex;
    gap: 8px;
}

.gigauser-toolbar-regular-buttons{

    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0px 10px;

    .gigauser-RichTextToolbar-reg-icon{
        width: 15px;
        height: 15px;
        padding: 3px;
        border-radius: 9999px;

        &:hover{
            background-color: $mediumGrey;
        }
    }
}


.gigauser-enhanceLoadingRing{
    display: flex;
    justify-content: center;
    align-items: center;
}


.voiceIcon-container{
    display: flex;
    align-items: center;
    font-size: 0.8em;
    border: 1px solid $mediumGrey;
    padding: 3px 8px;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;

    .gigauser-voice-name{
        max-width: 80px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1; 
        -webkit-box-orient: vertical;
    }

    .gigauser-RichTextToolbar-voice-icon{
        width: 14px;
        height: 14px;
    }


}