@import '../../../styles/colors';
@import '../../../styles/shadows';

.gigauser-guide-viewer-container{
    position: relative;
    display: flex;
    flex-flow: column;

    &.gigauser-mobile{
        height: calc(100vh - 43px);
    }
    //overflow: hidden;
}

.gigauser-viewer-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;

    &.gigauser-mobile{
        background-color: var(--gigauser-brand-color);
        padding: 20px;
    }

    &.gigauser-laptop{
        margin-top: 20px;
    }


}

.gigauser-viewer-body{
    padding: 0px 24px;

    &.gigauser-mobile{
        box-sizing: border-box;
        overflow-y: auto;
    }
}


.gigauser-viewer-back{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 8px;
    transition: 0.3s;
    border-radius: 99px;
    font-weight: 600;

    &.gigauser-laptop{
        color: var(--gigauser-brand-color);
        background-color: $clipboardGrey;

        &:hover{
            background-color: var(--gigauser-brand-color);
            color: white;
            .gigauser-viewer-back-button{
                // translate: 5px;
                filter: $filter_white;
            }
        }
    }

    

    .gigauser-viewer-back-button{
        height: 20px;
        width: 20px;
        transform: rotate(90deg);
        transition: 0.3s;
        border-radius: 99px;

        &.gigauser-mobile{

            &:hover{
                background-color: rgba(255, 255, 255, 0.173);
            }
            
        }
    }

    .gigauser-header-title{
        margin-left: 10px;
        color: white;
        font-size: 1.2em;
        max-width: 230px;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1; 
        -webkit-box-orient: vertical;
    }

    .gigauser-back-text{
        margin-left: 10px;
    }

   
}

.gigauser-viewer-close-helpcenter {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    border-radius: 999px;
    cursor: pointer;
    transition: 0.3s;
    opacity: 80%;

    &:hover {
        opacity: 60%;
    }
}

// .gigauser-top-sticky{
//     position: sticky;
//     top: 0;
//     z-index: 3;
// }

.gigauser-viewer-title{

    text-align: left;
    color: black;

    .gigauser-viewer-title-text{
        text-align: left;
        margin: 10px 0px;

        &.gigauser-name{
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 1.5em;
            font-weight: bold;

            &.l{
                font-size: 1.8em;
            }

        }

        &.gigauser-description{
            color: $darkGrey;
            font-weight: bold;

            &.l{
                font-size: 1.2em;
                line-height: 1.6em;
            }
            
        }

    }

    &.gigauser-loading-title{
        .gigauser-viewer-title-text{
            background-color: $lightGrey;
            color: transparent;
            border-radius: 5px;
            padding: 4px;
        }

        .gigauser-description{
            width: 70%;
            color: transparent;
        
        }
    }

}

.gigauser-viewer-tabs{
    margin: 14px 0px;
    margin-top: 20px;
}

.gigauser-guide-container{
    text-align: left;
    padding: 0px;
}

.gigauser-loading-p{
    background-color: $lightGrey;
    border-radius: 5px;
    color: transparent;
    padding: 5px 0px;
    margin: 5px 0px;
}

.gigauser-loading-img{
    background-color: $lightGrey;
    border-radius: 5px;
    color: transparent;
    margin: 15px 0px;
    height: 200px;

}

.gigauser-loading-title, .gigauser-loading-guide{
    overflow-x: hidden;
    overflow-y: hidden;
    &:after{
        background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
        content: "";
        display: block;
        position: absolute;
        top: 40px;
        width: 100%;
        height:100%;
        transform: translateX(-100px);
        animation: gigauserloadingflashes 0.8s infinite;

    }
}



@keyframes gigauserloadingflashes {
    100% {
        transform: translateX(100%);
    }
}

.light{
    .gigauser-viewer-title-text{
        color: black;
    }
}


.gigauser-dark{
    .gigauser-viewer-title-text{
        color: var(--gigauser-darkMode-brand-color);

        &.gigauser-description{
            color: $darkModeTextColor;
        }
    }



    .gigauser-loading-title, .gigauser-loading-guide{
        &:after{
            display: none;
            background: linear-gradient(90deg, transparent, $darkModeBg, transparent);

        }
    }

    .gigauser-loading-title{
        .gigauser-viewer-title-text{
            background-color: $darkestGrey;
        }
    }

    .gigauser-loading-img, .gigauser-loading-p{
        background-color: $darkestGrey;
    }


    
}