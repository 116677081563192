@import '../../../../styles/colors';
@import '../../../../styles/shadows';

$folderWidth: 20%;
$textWidth: 80%;
$arrowWidth: 10%;

.HelpCenterv2-home-container{



}

.HelpCenterv2-home-contents{

    text-align: left;
    padding: 0px 11em;
    padding-bottom: 100px;

    .guides-title{
        font-weight: bold;
        color: var(--gigauser-brand-color);
        font-size: 1.4em;
        margin: 1.6em 0em;
    }


}

.GuideBox-v2{
    width: calc(33.33% - 4em);

}

.CollectionBox-v2{
    width: calc(33.33% - 4em);
    
    text-align: left;
    
    .CollectionBox-v2-folder{
        width: 2.5em;
        height: 2.5em;
        margin-bottom: 1em;
    }

    .CollectionBox-v2-description{
        margin-bottom: 2.3em;
    }

    .CollectionBox-v2-explore{
        color: var(--gigauser-brand-color);

    }

}

.home-guides-container{

    display: flex;
    flex-wrap: wrap;
    gap: 1em;

}

.HomeBox{
    border: 1px solid $mediumGrey;
    border-radius: 5px;
    padding: 1.6em;
    cursor: pointer;
    transition: 0.3s;

    .homeItem-title{
        font-weight: bold;
        font-size: 1.1em;
        margin-bottom: 0.6em;
        transition: 0.3s;
    }

    .homeItem-description{
        font-size: 1.0em;
    }

    &:hover{
        border: 1px solid var(--gigauser-brand-color);
        box-shadow: $boxShadow;
        .homeItem-title{
            color: var(--gigauser-brand-color);
        }
        .CollectionBox-v2-explore{
            color: var(--gigauser-brand-color);
        }
    }




}