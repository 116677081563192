@import '../../../styles/colors';
@import '../../../styles/shadows';


.gigauser-video-container {
    position: relative;
    // width: 100%;
    // max-width: 800px;
    margin: 0 auto;
  }



.gigauser-play-button{

    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-color: var(--gigauser-brand-color);
    opacity: 0.6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;

    &.gigauser-isPlaying{
        display: none;
    }
    
      
}

.gigauser-play-button::before {
    content: "";
    margin-left: 4px;
    border-style: solid;
    border-width: 12px 0 12px 24px;
    border-color: transparent transparent transparent white;
  }

  .gigauser-editor-video{
    width: 100%;
    border-radius: 10px;
    box-shadow: $boxShadow;
    border: none;
    margin-top: 20px;

    
}

.gigauser-video-container{
    &:hover{
        cursor: pointer;
        .gigauser-play-button{
            opacity: 1;
        }
    }
}

