.gigauser-CollectionList {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  position: relative;
}

.gigauser-CollectionList-title {
  margin: 0px;
  margin-bottom: 10px;
  color: var(--gigauser-brand-color);
}

.gigauser-CollectionList-collections {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.gigauser-CollectionList-collections::-webkit-scrollbar {
  width: 8px;
}
.gigauser-CollectionList-collections::-webkit-scrollbar-track {
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.gigauser-CollectionList-collections::-webkit-scrollbar-thumb {
  background-color: #c2c1c1;
  border-radius: 20px;
  border: 2px solid white;
  padding-top: 10px solid white;
}

.gigauser-collectionlist-header {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-flow: column;
}

.gigauser-CollectionPreview {
  border-bottom: 1px solid #c7c7c7;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
  height: 40px;
  text-align: left;
  padding: 10px;
}
.gigauser-CollectionPreview:hover {
  background-color: rgba(var(--gigauser-brand-color-rgb), 0.15);
}
.gigauser-CollectionPreview:hover .gigauser-Preview-arrow {
  margin-right: 10px;
}
.gigauser-CollectionPreview.lastRow {
  border-bottom: 0px;
}
.gigauser-CollectionPreview .gigauser-CollectionName {
  font-size: 1em;
  font-weight: 600;
}
.gigauser-CollectionPreview .gigauser-CollectionDescription {
  font-size: 0.8em;
}
.gigauser-CollectionPreview .gigauser-Preview-arrow {
  transition: 0.3s;
  width: 20px;
  transform: rotate(-90deg);
}

.gigauser-CollectionList-collections.gigauser-CollectionList-loading .gigauser-CollectionBox:after {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  animation: gigauserloadingflashes 0.8s infinite;
}/*# sourceMappingURL=CollectionList.css.map */