@import '../../../../../../styles/colors';

.ProseMirror.remirror-editor hr{
    border: none; 
    height: 1px;
    background-color: #e0e0e0;  /* Soft grey color */
    margin: 30px 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); /* Very subtle shadow for depth */
}
