@import "../../styles/colors";

.gigauser-blocking-container{
    all: revert;
    position : fixed;
    left:  0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    z-index : 10000;
    // cursor : not-allowed;
}

.gigauser-blocking-container *{
    all: revert;
    font-family: 'Open Sans';
}

.gigauser-blocking-container .gigauser-guided-message {
    padding: 10px;
    position: fixed;
    top: 10px;
    left: 50%;
    border-radius: 5px;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background-color: var(--gigauser-brand-color);
    color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-blocking-container .gigauser-flashing-container{
    position: fixed;
    // border: 2px solid var(--gigauser-brand-color);
    animation: fade-in 0.5s forwards;
    // border: 2px solid $pink;
    &.editor{
        animation: none;
        border: 2px solid $pink;
    }
    // box-shadow: 0 2px 4px 0 #ff4d4d, 0 3px 10px 0 #ff4d4dd6;
}

.gigauser-blocking-container .gigauser-clickthrough-opacity{
    position: fixed;
    pointer-events: none;
    background-color: #000;
    opacity: 0.5;
}


.gigauser-blocking-container .gigauser-tour-error{
    padding: 10px;
    position: fixed;
    top: 10px;
    left: 50%;
    border-radius: 5px;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background-color: $pink;
    color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-blocking-container .gigauser-tour-complete{
    padding: 10px;
    position: fixed;
    top: 10px;
    left: 50%;
    border-radius: 5px;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background-color: var(--gigauser-brand-color);
    color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-blocking-container .gigauser-end-guided-tour{
    margin: 0;
    margin-left: 10px;
    background-color: $pink;
    border-radius: 4px;
    padding: 5px;
    color: white;
}

@keyframes fade-in {
    0%{
        background-color: #000;
        opacity: 0.7;
    }
    100%{
        background-color: #000;
        opacity: 0;
    }
}

@keyframes border-pulsate {
    0% {
        border-color: var(--gigauser-brand-color);
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: var(--gigauser-brand-color);
    }
}
