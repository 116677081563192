@import '../../../../styles/colors';

$height: 323px;

.FolderAccordion{
    width: 100%;
    max-width: 24em;
}

.accordion-folder-container{

    display: flex;
    flex-direction: column;

    .accordion-folder{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0.1em 0em;
        
        .accordion-folder-name{
            cursor: pointer;
            white-space: nowrap;
            overflow-wrap: unset;
            width: 80%;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
            
            &.primary-accordion-folder{
                font-weight: 600;
            }

        }


        .accordion-folder-icon{
            padding: 2px;
            cursor: pointer;
            height: 1.4em;
            width: 1.4em;
            transition: 0.3s;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.explored{
                transform: rotate(0deg);
            }

            &.not-explored{
                transform: rotate(-90deg);
            }

        

        }
    
        .folder-icon-name{
    
            display: flex;
            cursor: pointer;
    
            .folder-icon{
                padding: 0px 5px;
                display: flex;
                align-items: center;
            }

            .opened{
                font-weight: bold;
            }
        }
    }

    .isDropActive{
        border: 1px $darkPurple solid;
        background-color: $lightPurple;
    }

    .explored-folder-list{
        padding-left: 20px;
    }
}

.no-folders{
    font-size: 0.9em;
    padding-left: 24px;
    margin-top: 0.4em;
    margin-bottom: 1em ;
    color: gray;
}


.accordion-item{
    line-height: 1.4em;
    padding: 0.5em;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;
    border-radius: 5px;
    font-size: 0.9em;
    
    &.accordion-inactive-item{
        &:hover{
            background-color: $clipboardGrey;
        }
    }
    
}

.accordion-guide{
    color: $darkGrey;
    margin-bottom: 0.2em;
    
}

.accordion-guide-name{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.accordion-active-item{
    background-color: rgb(var(--gigauser-brand-color-rgb), 0.1 );
    color: var(--gigauser-brand-color);
    font-weight: bold;
}

.light{
    .accordion-folder-icon{
        

        &:hover{
            background-color: $mediumGrey;
        }

    }
}

.gigauser-dark{
    .accordion-guide-name{
        color: $darkModeTextColor;
      
    }
    .accordion-folder-name{
        color: white;
    }

    .accordion-active-item{
        background-color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1 );
        color: var(--gigauser-darkMode-brand-color);
        font-weight: bold;

        .accordion-folder-name{
            color: var(--gigauser-darkMode-brand-color);
        }

        .accordion-guide-name{
            color: var(--gigauser-darkMode-brand-color);
        }
    }

    .accordion-inactive-item{
        &:hover{
            background-color: $darkestGrey;
        }
    }

    .accordion-folder-icon{
        

        &:hover{
            background-color: $hoverTipGrey;
        }

    }

    
}