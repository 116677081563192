
@import '../../styles/colors';


.gigauser-help-footer{
    // display: flex;
    font-size: 0.9em;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    justify-content: center;
    border-top: 1px solid $lightGrey;
    user-select: none;
    background-color: $offWhite;
    margin-top: auto;
    box-shadow: 1px -1px 8px 0px rgba(0, 0, 0, 0.06);

    .gigauser-footer-logo {
        display: flex;
    }
    .gigauser-footer-logo svg{
        height: 1.5em;
        width: 4.5em;
        margin-left: 10px;
        cursor: pointer;
    }

}

.gigauser-footer-buttons{
    // border-top: 1px solid $mediumGrey;
    background-color: white;
    display: flex;
    padding: 12px 0px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;

    

    .gigauser-footer-tabs{
        width: 40%;
        display: flex;
        font-weight: bold;
        font-size: 1.0em;
        justify-content: space-between;
    

        justify-content: space-between;

    }

    .gigauser-footer-tab-icon{
        width: 20px;
        height: 20px;
        margin-bottom: 6px;
    }

    .gigauser-footer-tab{

        transition: 0.3s;

        &.gigauser-inactive-footer{
            &:hover{
                filter: $filter_darkGrey
            }
        }

        &.gigauser-active-footer{
            color: var(--gigauser-brand-color);
        }
        
    }
}

.gigauser-clueso-footer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    // border-top: 1px solid $mediumGrey;

    padding-top: 10px;
    padding-bottom: 10px;

}