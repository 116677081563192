@import '../../styles/colors';

$trackColor: white ;
$thumbColor: $inputGrey ;
$borderRadius: 5px;

.gigauser-CollectionList{
    padding-left: 20px;
    padding-right: 20px;    
    padding-bottom: 20px;
    position: relative;
}

.gigauser-CollectionList-title{
    margin: 0px;
    margin-bottom: 10px;
    color: var(--gigauser-brand-color);
}

.gigauser-CollectionList-collections{

    display: flex;
    flex-direction: column;
    gap: 16px;

    &::-webkit-scrollbar {
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        background: $trackColor;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        // border-left: 1px solid grey;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: $thumbColor;
        border-radius: 20px;
        // margin-right: 5px;
        border: 2px solid $trackColor;
        padding-top: 10px solid $trackColor;
        // border-left: 1px solid grey;
    }

}

.gigauser-collectionlist-header{

    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-flow: column;
    
}

.gigauser-CollectionPreview{
    border-bottom: 1px solid $mediumGrey;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s;
    height: 40px;

    &:hover{

        background-color:rgba(var(--gigauser-brand-color-rgb), $lightenValue);

        .gigauser-Preview-arrow{
            margin-right: 10px;
        }
    }

    &.firstRow{
        // border-top-right-radius: $borderRadius;
        // border-top-left-radius: $borderRadius;

    }

    &.lastRow{
        border-bottom: 0px;
        // border-bottom-right-radius: $borderRadius;
        // border-bottom-left-radius: $borderRadius;

    }
    text-align: left;
    padding: 10px ;

    .gigauser-CollectionName{
        font-size: 1.0em;
        font-weight: 600;
    }

    .gigauser-CollectionDescription{
        font-size: 0.8em;
    }

    .gigauser-Preview-arrow{
        transition: 0.3s;
        width: 20px;
        transform: rotate(-90deg);
    }

}

.gigauser-CollectionList-collections.gigauser-CollectionList-loading{
    .gigauser-CollectionBox{

        &:after{
            background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height:100%;
            transform: translateX(-100px);
            animation: gigauserloadingflashes 0.8s infinite;

        }
        
    }
}