
@import '../../../styles/colors';
@import '../../../styles/shadows';

$darkBorderColor: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.3);


.CollectionViewer{

    text-align: left;
    padding: 0px 24px ;
    padding-bottom: 4em;

    .CollectionViewer-header{
        margin: 2em 0em;
        padding-bottom: 2em;

        .CollectionViewer-title{
            margin: 0.6em 0em;
            font-weight: bold;
            font-size: 1.5em;
        }


        .CollectionViewer-description{
            font-weight: 600;
            margin-bottom: 1em;
            font-size: 1.2em;
        }

        .CollectionViewer-subtitle{
            font-size: 0.8em;
        }
    }

    .guides-title{
        margin: 1em 0em;
        font-weight: bold;
        font-size: 1.2em;
        color: var(--gigauser-brand-color);
    }

}



.Browser-GuideBox-v2{

    width: calc(33.33% - 2em);
    display: flex;
    align-items: center;
    border: 1px solid $mediumGrey;
    padding: 0.6em;
    border-radius: 10px;
    transition: 0.3s;

    // border: 1px solid green;

    .Browser-GuideBox-icon{
        height: 24px;
        width: 24px;
        padding: 5px;
        border: 1px solid $clipboardGrey;
        border-radius: 5px;
        margin-right: 1em;
        background-color: white;
    }

    .Broswer-GuideBox-v2-text{
        display: flex;
        flex-flow: column;
    }

    .Browser-GuideBox-v2-title{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1; 
        -webkit-box-orient: vertical;
        font-weight: 600;
    }

    .Browser-GuideBox-v2-description{
        line-height: 1.4em;
        margin-top: 0.4em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1; 
        -webkit-box-orient: vertical;
    }

    &:hover{
        border: 1px solid var(--gigauser-brand-color);
        box-shadow: $boxShadow;
        .Browser-GuideBox-v2-description, .Browser-GuideBox-v2-title{
            color: var(--gigauser-brand-color);
        }
    }

}

.Browser-CollectionBox-v2{
    width: calc(33.33% - 4em);
    border-radius: 5px;
    padding: 1.6em  ;
    text-align: left;
    border: 1px solid $mediumGrey;
    transition: 0.3s;

    .Browser-CollectionBox-v2-folder{
        width: 2.5em;
        height: 2.5em;
        margin-bottom: 1em;
    }

    .Browser-CollectionBox-v2-title{
        margin-bottom: 0.9em;

        transition: 0.3s;
        font-weight: 600;

        font-weight: 600;

        .Browser-CollectionBox-v2-title-text{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
    
        }
    }

    .Browser-CollectionBox-v2-description{
        margin-bottom: 2.3em;
        line-height: 1.4em;
        min-height: 2.8em;
    }

    .Browser-CollectionBox-v2-explore{
        color: var(--gigauser-brand-color);

    }

    &:hover{
        border: 1px solid var(--gigauser-brand-color);
        box-shadow: $boxShadow;
        
    }

}

.browser-guides-container{

    display: flex;
    flex-wrap: wrap;
    gap: 1em;

}

.BrowserBox{
    
    cursor: pointer;

    .browserItem-title{
        font-size: 1em;
        line-height: 1.4em;
        // min-height: 2.8em;
    }

    .browserItem-description{
        font-size: 0.9em;

    }


}

.gigauser-light{

    .CollectionViewer-description{
        color: $darkGrey;
    }

    .CollectionViewer-subtitle{
        color: $darkerGrey;
    }

    .CollectionViewer-header{
        border-bottom: 1px solid $bluishGrey;

    }


    .BrowserBox{
        
        &:hover{
            .Browser-CollectionBox-v2-title{
                color: var(--gigauser-brand-color);
            }
           
            .Browser-CollectionBox-v2-explore{
                color: var(--gigauser-brand-color);
            }
        }
    
    
    }
}

.gigauser-dark{

    .guides-title{
        color: var(--gigauser-darkMode-brand-color);
    }

    .CollectionViewer-title{
        color: var(--gigauser-darkMode-brand-color);
    }

    .CollectionViewer-description{
        color: white;
    }

    .CollectionViewer-subtitle{
        color: $darkModeTextColor;
    }


    .Browser-GuideBox-icon{
        background-color: $darkModeBg;
        border: 1px solid $darkBorderColor;

    }

    .Browser-GuideBox-v2-title{
        color: white;
    }

    .Browser-GuideBox-v2-description{
        color: $darkModeTextColor;

    }

    .BrowserBox{
        &:hover{
            .Browser-CollectionBox-v2-title{
                color: var(--gigauser-darkMode-brand-color);
            }
        
            .Browser-CollectionBox-v2-explore{
                color: var(--gigauser-darkMode-brand-color);
            }
        }
    }
    
    .Browser-GuideBox-v2{

        border: 1px solid  $darkBorderColor;

        &:hover{
            border: 1px solid var(--gigauser-darkMode-brand-color);
            box-shadow: 0 2px 4px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2), 0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
            .Browser-GuideBox-v2-description, .Browser-GuideBox-v2-title{
                color: var(--gigauser-darkMode-brand-color);
            }
        }
    }

    .Browser-CollectionBox-v2{
        border: 1px solid $darkBorderColor;
  

        .Browser-CollectionBox-v2-explore{
            color: var(--gigauser-darkMode-brand-color);
    
        }

        .Browser-CollectionBox-v2-title{
            color: white;
        }

        .Browser-CollectionBox-v2-description{
            color: $darkModeTextColor;
        }
    
        &:hover{
            border: 1px solid var(--gigauser-darkMode-brand-color);
            box-shadow: 0 2px 4px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2), 0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
            
        }
    
    }

    .CollectionViewer-header{
        border-bottom: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.3);
    }
    
}