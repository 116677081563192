.gigauser-guide-viewer-container {
  position: relative;
  display: flex;
  flex-flow: column;
}
.gigauser-guide-viewer-container.gigauser-mobile {
  height: calc(100vh - 43px);
}

.gigauser-viewer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}
.gigauser-viewer-header.gigauser-mobile {
  background-color: var(--gigauser-brand-color);
  padding: 20px;
}
.gigauser-viewer-header.gigauser-laptop {
  margin-top: 20px;
}

.gigauser-viewer-body {
  padding: 0px 24px;
}
.gigauser-viewer-body.gigauser-mobile {
  box-sizing: border-box;
  overflow-y: auto;
}

.gigauser-viewer-back {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 8px;
  transition: 0.3s;
  border-radius: 99px;
  font-weight: 600;
}
.gigauser-viewer-back.gigauser-laptop {
  color: var(--gigauser-brand-color);
  background-color: #f2f2f7;
}
.gigauser-viewer-back.gigauser-laptop:hover {
  background-color: var(--gigauser-brand-color);
  color: white;
}
.gigauser-viewer-back.gigauser-laptop:hover .gigauser-viewer-back-button {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}
.gigauser-viewer-back .gigauser-viewer-back-button {
  height: 20px;
  width: 20px;
  transform: rotate(90deg);
  transition: 0.3s;
  border-radius: 99px;
}
.gigauser-viewer-back .gigauser-viewer-back-button.gigauser-mobile:hover {
  background-color: rgba(255, 255, 255, 0.173);
}
.gigauser-viewer-back .gigauser-header-title {
  margin-left: 10px;
  color: white;
  font-size: 1.2em;
  max-width: 230px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.gigauser-viewer-back .gigauser-back-text {
  margin-left: 10px;
}

.gigauser-viewer-close-helpcenter {
  height: 16px;
  width: 16px;
  margin-right: 8px;
  border-radius: 999px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 80%;
}
.gigauser-viewer-close-helpcenter:hover {
  opacity: 60%;
}

.gigauser-viewer-title {
  text-align: left;
  color: black;
}
.gigauser-viewer-title .gigauser-viewer-title-text {
  text-align: left;
  margin: 10px 0px;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-name {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: bold;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-name.l {
  font-size: 1.8em;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-description {
  color: #727272;
  font-weight: bold;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-description.l {
  font-size: 1.2em;
  line-height: 1.6em;
}
.gigauser-viewer-title.gigauser-loading-title .gigauser-viewer-title-text {
  background-color: #f0f3ff;
  color: transparent;
  border-radius: 5px;
  padding: 4px;
}
.gigauser-viewer-title.gigauser-loading-title .gigauser-description {
  width: 70%;
  color: transparent;
}

.gigauser-viewer-tabs {
  margin: 14px 0px;
  margin-top: 20px;
}

.gigauser-guide-container {
  text-align: left;
  padding: 0px;
}

.gigauser-loading-p {
  background-color: #f0f3ff;
  border-radius: 5px;
  color: transparent;
  padding: 5px 0px;
  margin: 5px 0px;
}

.gigauser-loading-img {
  background-color: #f0f3ff;
  border-radius: 5px;
  color: transparent;
  margin: 15px 0px;
  height: 200px;
}

.gigauser-loading-title, .gigauser-loading-guide {
  overflow-x: hidden;
  overflow-y: hidden;
}
.gigauser-loading-title:after, .gigauser-loading-guide:after {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  content: "";
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  animation: gigauserloadingflashes 0.8s infinite;
}

@keyframes gigauserloadingflashes {
  100% {
    transform: translateX(100%);
  }
}
.light .gigauser-viewer-title-text {
  color: black;
}

.gigauser-dark .gigauser-viewer-title-text {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .gigauser-viewer-title-text.gigauser-description {
  color: #bfc7d4;
}
.gigauser-dark .gigauser-loading-title:after, .gigauser-dark .gigauser-loading-guide:after {
  display: none;
  background: linear-gradient(90deg, transparent, rgb(15, 17, 23), transparent);
}
.gigauser-dark .gigauser-loading-title .gigauser-viewer-title-text {
  background-color: #303030;
}
.gigauser-dark .gigauser-loading-img, .gigauser-dark .gigauser-loading-p {
  background-color: #303030;
}/*# sourceMappingURL=Viewer.css.map */