.gigauser-blocking-container .gigauser-popup-tooltip {
    position: absolute;
    width: 250px;
    // border-radius: 2px;
    top: 0;
    left: 0;
    // HAVE TO SPECIFY IN JS
    cursor: default;
    .gigauser-popup-header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 12px 12px 12px 12px;
        padding-bottom: 0;
        overflow: visible;
        box-sizing: border-box;
        .gigauser-popup-title {
            flex-grow: 1;
            padding: 0;
            font-size: 16px;
            font-weight: bold;
        }

        .gigauser-popup-close {
            float: right;
            margin-right: 0;
            margin-left: 10px;
            width: 12px;
            height: 12px;
            cursor: pointer;
        }
    }

    .gigauser-popup-body {
        display: flex;
        font-size: 14px;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin: 18px 18px;
        word-wrap: break-word;
        white-space: pre-line;
        p{
            margin: 0;
            word-wrap: break-word;
            width: 100%;
            color: black;
        }
    }

    .gigauser-popup-arrow{
        position: absolute;
        width: 20px;
        height:16px;
    }

    .gigauser-popup-footer {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        justify-content: flex-end;
        padding: 10px;

        .gigauser-popup-next {
            float: right;
            margin: 0;
            margin-left: 10px;
            background-color: var(--gigauser-brand-color);
            border-radius: 4px;
            padding: 5px 9px 5px 9px;
            color: white;
            border: none;
            cursor: pointer;
        }
    }
}
