$red: #b72121;

.gigauser-ExitTourModal-Parent{
    pointer-events: auto;
}

.gigauser-ExitTourModal{
    pointer-events: auto;
    background-color: rgba(255, 255, 255);
    padding: 16px;
    text-align: center;
    width: 320px;
    position: absolute;
    top: 40px;
    left: 50vw;
    translate: -125px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);;
}

.gigauser-exit-buttons{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 12px;
}

.gigauser-exit-button{
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 3px;
    width: 50%;
    border: none;
    transition: 0.3s;
    cursor: pointer;

    &.gigauser-exit{
        color: $red;
        border: 1px solid $red;

        &:hover{
            background-color: $red;
            color: white;
        }
    }

    &.gigauser-cancel{
        background-color: var(--gigauser-brand-color);
        color: white;
    }

}