.CollectionViewer {
  text-align: left;
  padding: 0px 24px;
  padding-bottom: 4em;
}
.CollectionViewer .CollectionViewer-header {
  margin: 2em 0em;
  padding-bottom: 2em;
}
.CollectionViewer .CollectionViewer-header .CollectionViewer-title {
  margin: 0.6em 0em;
  font-weight: bold;
  font-size: 1.5em;
}
.CollectionViewer .CollectionViewer-header .CollectionViewer-description {
  font-weight: 600;
  margin-bottom: 1em;
  font-size: 1.2em;
}
.CollectionViewer .CollectionViewer-header .CollectionViewer-subtitle {
  font-size: 0.8em;
}
.CollectionViewer .guides-title {
  margin: 1em 0em;
  font-weight: bold;
  font-size: 1.2em;
  color: var(--gigauser-brand-color);
}

.Browser-GuideBox-v2 {
  width: calc(33.33% - 2em);
  display: flex;
  align-items: center;
  border: 1px solid #c7c7c7;
  padding: 0.6em;
  border-radius: 10px;
  transition: 0.3s;
}
.Browser-GuideBox-v2 .Browser-GuideBox-icon {
  height: 24px;
  width: 24px;
  padding: 5px;
  border: 1px solid #f2f2f7;
  border-radius: 5px;
  margin-right: 1em;
  background-color: white;
}
.Browser-GuideBox-v2 .Broswer-GuideBox-v2-text {
  display: flex;
  flex-flow: column;
}
.Browser-GuideBox-v2 .Browser-GuideBox-v2-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 600;
}
.Browser-GuideBox-v2 .Browser-GuideBox-v2-description {
  line-height: 1.4em;
  margin-top: 0.4em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Browser-GuideBox-v2:hover {
  border: 1px solid var(--gigauser-brand-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}
.Browser-GuideBox-v2:hover .Browser-GuideBox-v2-description, .Browser-GuideBox-v2:hover .Browser-GuideBox-v2-title {
  color: var(--gigauser-brand-color);
}

.Browser-CollectionBox-v2 {
  width: calc(33.33% - 4em);
  border-radius: 5px;
  padding: 1.6em;
  text-align: left;
  border: 1px solid #c7c7c7;
  transition: 0.3s;
}
.Browser-CollectionBox-v2 .Browser-CollectionBox-v2-folder {
  width: 2.5em;
  height: 2.5em;
  margin-bottom: 1em;
}
.Browser-CollectionBox-v2 .Browser-CollectionBox-v2-title {
  margin-bottom: 0.9em;
  transition: 0.3s;
  font-weight: 600;
  font-weight: 600;
}
.Browser-CollectionBox-v2 .Browser-CollectionBox-v2-title .Browser-CollectionBox-v2-title-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Browser-CollectionBox-v2 .Browser-CollectionBox-v2-description {
  margin-bottom: 2.3em;
  line-height: 1.4em;
  min-height: 2.8em;
}
.Browser-CollectionBox-v2 .Browser-CollectionBox-v2-explore {
  color: var(--gigauser-brand-color);
}
.Browser-CollectionBox-v2:hover {
  border: 1px solid var(--gigauser-brand-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.browser-guides-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.BrowserBox {
  cursor: pointer;
}
.BrowserBox .browserItem-title {
  font-size: 1em;
  line-height: 1.4em;
}
.BrowserBox .browserItem-description {
  font-size: 0.9em;
}

.gigauser-light .CollectionViewer-description {
  color: #727272;
}
.gigauser-light .CollectionViewer-subtitle {
  color: #4b4b4b;
}
.gigauser-light .CollectionViewer-header {
  border-bottom: 1px solid #D6E2F0;
}
.gigauser-light .BrowserBox:hover .Browser-CollectionBox-v2-title {
  color: var(--gigauser-brand-color);
}
.gigauser-light .BrowserBox:hover .Browser-CollectionBox-v2-explore {
  color: var(--gigauser-brand-color);
}

.gigauser-dark .guides-title {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .CollectionViewer-title {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .CollectionViewer-description {
  color: white;
}
.gigauser-dark .CollectionViewer-subtitle {
  color: #9ca3af;
}
.gigauser-dark .Browser-GuideBox-icon {
  background-color: rgb(15, 17, 23);
  border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.3);
}
.gigauser-dark .Browser-GuideBox-v2-title {
  color: white;
}
.gigauser-dark .Browser-GuideBox-v2-description {
  color: #9ca3af;
}
.gigauser-dark .BrowserBox:hover .Browser-CollectionBox-v2-title {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .BrowserBox:hover .Browser-CollectionBox-v2-explore {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .Browser-GuideBox-v2 {
  border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.3);
}
.gigauser-dark .Browser-GuideBox-v2:hover {
  border: 1px solid var(--gigauser-darkMode-brand-color);
  box-shadow: 0 2px 4px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2), 0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
}
.gigauser-dark .Browser-GuideBox-v2:hover .Browser-GuideBox-v2-description, .gigauser-dark .Browser-GuideBox-v2:hover .Browser-GuideBox-v2-title {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .Browser-CollectionBox-v2 {
  border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.3);
}
.gigauser-dark .Browser-CollectionBox-v2 .Browser-CollectionBox-v2-explore {
  color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .Browser-CollectionBox-v2 .Browser-CollectionBox-v2-title {
  color: white;
}
.gigauser-dark .Browser-CollectionBox-v2 .Browser-CollectionBox-v2-description {
  color: #9ca3af;
}
.gigauser-dark .Browser-CollectionBox-v2:hover {
  border: 1px solid var(--gigauser-darkMode-brand-color);
  box-shadow: 0 2px 4px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2), 0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
}
.gigauser-dark .CollectionViewer-header {
  border-bottom: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.3);
}/*# sourceMappingURL=CollectionViewer.css.map */