@import '../../../styles/colors';

.gigauser-Breadcrumbs{
    display: flex;
    align-items: center;
    font-size: 0.8em;
}

.gigauser-PageTitle-pathSegment{
    display: inline-block;
    border-radius: 999px;
    transition: 0.3s;
    padding: 4px 8px;
    color: $darkGrey;

    &.gigauser-home-segment{
        margin-left: -8px;
    }

    &:hover{
        cursor: pointer;
        color: var(--gigauser-brand-color);
        background-color: $clipboardGrey;

    }

    &.onDrop{
        color: black;
        background-color: $darkPurple;
        padding: 0px 10px;
        border-radius: 3px;
    }

    &.gigauser-activeSegment{
        background-color: $clipboardGrey;
        color: var(--gigauser-brand-color);
        font-weight: bold;

    }
    
}

.gigauser-breadcrumbs-home{
    width: 20px;
    height: 20px;
    display: inline-block;
}

.gigauser-Breadcrumb-arrow{
    transform: rotate(270deg);
    width: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    transition: 0.3s;

}

.droppable-pathSegment{
    cursor: pointer;
    &:hover{
        color: $darkPurple;
    }
}

.gigauser-dark{

    .gigauser-PageTitle-pathSegment{
        color: $darkModeTextColor;

        &:hover{
            background-color: $darkerGrey;
            color: var(--gigauser-darkMode-brand-color);
        }

        &.gigauser-activeSegment{
            background-color: $darkestGrey;
            color: var(--gigauser-darkMode-brand-color);
        }
    }

}