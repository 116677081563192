.gigauser-blocking-container {
  all: revert;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}

.gigauser-blocking-container * {
  all: revert;
  font-family: "Open Sans";
}

.gigauser-blocking-container .gigauser-guided-message {
  padding: 10px;
  position: fixed;
  top: 10px;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, 0%);
  background-color: var(--gigauser-brand-color);
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-blocking-container .gigauser-flashing-container {
  position: fixed;
  animation: fade-in 0.5s forwards;
}
.gigauser-blocking-container .gigauser-flashing-container.editor {
  animation: none;
  border: 2px solid #d43f8c;
}

.gigauser-blocking-container .gigauser-clickthrough-opacity {
  position: fixed;
  pointer-events: none;
  background-color: #000;
  opacity: 0.5;
}

.gigauser-blocking-container .gigauser-tour-error {
  padding: 10px;
  position: fixed;
  top: 10px;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, 0%);
  background-color: #d43f8c;
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-blocking-container .gigauser-tour-complete {
  padding: 10px;
  position: fixed;
  top: 10px;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, 0%);
  background-color: var(--gigauser-brand-color);
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-blocking-container .gigauser-end-guided-tour {
  margin: 0;
  margin-left: 10px;
  background-color: #d43f8c;
  border-radius: 4px;
  padding: 5px;
  color: white;
}

@keyframes fade-in {
  0% {
    background-color: #000;
    opacity: 0.7;
  }
  100% {
    background-color: #000;
    opacity: 0;
  }
}
@keyframes border-pulsate {
  0% {
    border-color: var(--gigauser-brand-color);
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: var(--gigauser-brand-color);
  }
}/*# sourceMappingURL=tour.css.map */