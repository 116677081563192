.gigauser-simple-tabs {
  background-color: none;
  display: flex;
  gap: 16px;
}
.gigauser-simple-tabs .gigauser-simple-tab-icon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.gigauser-simple-tabs .gigauser-simple-tab-icon.s {
  height: 16px;
  width: 16px;
}
.gigauser-simple-tabs .gigauser-simple-tab {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4b4b4b;
  font-weight: 600;
  transition: 0.3s;
}
.gigauser-simple-tabs .gigauser-simple-tab:hover {
  color: #727272;
}
.gigauser-simple-tabs .gigauser-simple-tab.gigauser-active-tab {
  color: var(--gigauser-brand-color);
  background-color: #f0f3ff;
  font-weight: bold;
}
.gigauser-simple-tabs .gigauser-simple-tab.gigauser-s {
  font-size: 1.1em;
}/*# sourceMappingURL=SimpleTabs.css.map */