.HelpCenterIndex {
  box-sizing: border-box;
  width: 20em;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.05);
  padding: 0em 1.2em;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: left;
  padding-top: 6em;
  display: flex;
  margin-bottom: 2em;
  position: fixed;
}
.HelpCenterIndex.hide {
  display: none;
}

.mainFolderAccordion {
  margin-bottom: 2em;
  width: 100%;
}

.gigauser-dark .HelpCenterIndex {
  border-right: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
}/*# sourceMappingURL=HelpCenterIndex.css.map */