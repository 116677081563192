@import '../../../styles/colors';
@import '../../../styles/shadows';

$folderWidth: 20%;
$textWidth: 80%;
$arrowWidth: 10%;

.gigauser-CollectionBox{
    border: 1px solid $mediumGrey;
    padding: 16px;
    border-radius: 10px;
    transition: 0.3s;
    background-color: transparentize($clipboardGrey, 0.5);

    

    .gigauser-CollectionBox-columns{
        display: flex;
        align-items: center;

        .gigauser-CollectionBox-folder{
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            
        }

        .gigauser-CollectionBox-text{
            text-align: left;
            width: $textWidth;
            padding: 0px 14px;

            .gigauser-CollectionBox-title{

                font-weight: bold;
            }

            .gigauser-CollectionBox-description{
                font-size: 0.9em;
                width: 80%;

                
            }

            div{
                padding: 4px 0px;
            }
        }

        .gigauser-CollectionBox-arrow{
            height: 20px;
            width: 20px;
            transform: rotate(-90deg);
            transition: 0.3s;
        }
    }

    &.gigauser-CollectionBox-notloading:hover{
        cursor: pointer;
        .gigauser-CollectionBox-arrow{
            margin-right: 10px;
            
        }
        background-color: transparentize($clipboardGrey, 0.5);
        border: 1px solid var(--gigauser-brand-color);

    }
    
}

.gigauser-CollectionBox.gigauser-CollectionBox-loading{
    background-color: white;

    .gigauser-CollectionBox-title{
        // padding: 3px;
        border-radius: 3px;
        margin-bottom: 4px;
        color: transparent;
        background-color: $lightGrey;
    }

    .gigauser-CollectionBox-description{
        // padding: 3px;
        border-radius: 3px;
        color: transparent;
        background-color: $lightGrey;
    }

}