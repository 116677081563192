@import '../../../../../../styles/colors';

.gigauser-texteditor-toolbar-blockquote-dropdown {
    position: relative;
    display: flex;
  }
  
  .gigauser-texteditor-toolbar-blockquote-dropdown-content {
    display: none;
    position: absolute;
    // min-width: 160px;
    width: 54px;
    margin-top: 30px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }

  .gigauser-texteditor-toolbar-blockquote-dropdown-content-item:hover svg{
    color: white;
    cursor: pointer;
  }
//   .gigauser-texteditor-toolbar-blocktype-content * {
//     color: black;
//     text-decoration: none;
//     display: block;
//   }
  
  .gigauser-texteditor-toolbar-blockquote-dropdown:hover .gigauser-texteditor-toolbar-blockquote-dropdown-content {
    display: block;
  }
  

  .remirror-editor blockquote {
    position: relative;
  padding: 5px 30px 5px 30px;
  margin: 10px 0;
  border-radius: 5px;
  }
  
.remirror-editor blockquote.green{
    color: darkgreen;
    border-left: 4px solid green;
    background-color: rgb(217, 248, 217);
}

.remirror-editor blockquote.gray{
    color: #555;
  border-left: 4px solid $mediumGrey;
  background-color: $clipboardGrey;
}

.remirror-editor blockquote.red{
    color: maroon;
  border-left: 4px solid mediumvioletred;
  background-color: rgb(256, 220, 220);
}

.remirror-editor blockquote.yellow{
    color: rgb(131, 96, 8);
  border-left: 4px solid rgb(200, 200, 30);
  background-color: rgb(250, 240, 200);
}

.remirror-editor blockquote.blue{
    color: #006a8a;
  border-left: 4px solid rgb(105, 77, 243);
  background-color: #ddf7ff;
}
  
 .remirror-editor.ProseMirror blockquote cite {
    display: block;
    text-align: right;
    color: #333;
    font-size: 0.95em;
  }
  