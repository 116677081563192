
// @import '../../../../../../../common/src/styles/_variables.scss';
//TODO: This needs to be fixed entirely. Not to be injected into styles. 
@import '../../../styles/colors';
@import '../../../styles/shadows';

$fontFamily: 'Poppins';

.gigauser-TextEditor{
    display: flex;
    flex-flow: column;
    align-items: center;

    font-size: 0.95em;
    line-height: 1.5em;

    &.l{
        font-size: 1.1em;
        line-height: 1.8em;
        color: rgb(19, 19, 19);
    }
}

.gigauser-light {
    --main-text-color: black;
    
}

.gigauser-dark {
    --main-text-color: white;
    --check-color: $lightHover;
}

.remirror-floating-popover{
    z-index: 1;
}

.remirror-editor-wrapper {
    padding-top: 3px;
    width: 100%;
}

// .remirror-editor.ProseMirror p{
//     margin-bottom: 12px;
// }


.remirror-editor.ProseMirror h1 , .remirror-editor.ProseMirror h2 {
    margin-top: 22px;
    margin-bottom: 22px;
    line-height: normal;
}
.remirror-editor.ProseMirror h3, .remirror-editor.ProseMirror h4, .remirror-editor.ProseMirror h5{
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: normal;
}

.ProseMirror.remirror-editor{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // padding: 20px;
    padding-top: 0px;
    padding-bottom: 10px;
    box-sizing: border-box;
    //font-family: $fontFamily;
    padding: 0px;
}
/* Placeholder (at the top) */
.ProseMirror p.remirror-is-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }
  
  /* Placeholder (on every new line) */
  /*.ProseMirror p.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }*/
.ProseMirror.remirror-editor {
    box-shadow: none;
}

.ProseMirror.remirror-editor.ProseMirror-focused {
    box-shadow: none;
    outline: none;
    // border-top: 2px solid $menuBgColor;
}

.remirror-theme .ProseMirror:focus, .remirror-theme .ProseMirror:active{
    box-shadow: none;
}

.nb-header .nb-titlebar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    padding-top: 10px;
}
.remirror-toolbar button {
    border: 1px solid black;
    margin: 2px;
}
.notebook-container {
    box-sizing: border-box;
    // position: relative;
    width: 100%;
    height: calc(100vh - 140px);
    // border: 1px solid red;
    margin-top: 15px;
    overflow: scroll;
    //font-family: $fontFamily;
}
.light .notebook-container{
    color: black;
    --dropcursor-color: black;
}

.dark .notebook-container{
    color: white;
    --dropcursor-color: white;

}
.notebook-container img{
    -webkit-user-drag: auto;
    cursor: pointer;
}


.light .remirror-editor.ProseMirror ::selection, .light .remirror-editor.ProseMirror .selection {
    background:  #B4D7FF;
    caret-color: var(--rmr-color-selection-caret);
    color: inherit;
    // text-shadow: var(--rmr-color-selection-shadow);
}
.dark .remirror-editor.ProseMirror ::selection, .dark .remirror-editor.ProseMirror .selection {
    background: #5D83AC;
    color: inherit;
// caret-color: var(--rmr-color-selection-caret);
// text-shadow: var(--rmr-color-selection-shadow);
}

.remirror-collapsible-list-item-button.disabled, .remirror-collapsible-list-item-button.disabled:hover {
        background-color: var(--main-text-color);
        border: none;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        cursor: default;
}



.remirror-collapsible-list-item-button,  .remirror-collapsible-list-item-button:hover{

    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 0%;
    border-top: 8px solid var(--main-text-color);
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    transition: none;
    background-color: transparent;
}

.remirror-collapsible-list-item-closed .remirror-collapsible-list-item-button {
    background-color: transparent;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: none;
    border-radius: 0%;
    border-left: 8px solid var(--main-text-color);
}

// [data-checked]{
//     text-decoration: line-through;
// }


.remirror-editor input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
  
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.05em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
  }
  
  .remirror-editor input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    // box-shadow: inset 1em 1em var(--check-color, purple);
    /* Windows High Contrast Mode */
    // background-color: var(--check-color, purple);
  }

  .remirror-editor p{
    padding: 1px;
  }

//   .remirror-editor p[:dir=='ltr']{
//     padding:0px;

//   }


  .remirror-editor input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  .remirror-editor input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);
  
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

.light   .remirror-editor input[type="checkbox"]::before {
    background-color: steelblue;
}

.dark  .remirror-editor input[type="checkbox"]::before  {
    background-color: deepSkyBlue;
}

.light .remirror-list-spine {
    position: absolute;
    top: 4px;
    bottom: 0px;
    left: -20px;
    width: 16px;
    cursor: pointer;
    transition: border-left-color 0.25s ease;
    border-left-color: var(--rmr-color-border);
    border-left-style: solid;
    border-left-width: 1px;
}

.dark .remirror-list-spine {
    position: absolute;
    top: 4px;
    bottom: 0px;
    left: -20px;
    width: 16px;
    cursor: pointer;
    transition: border-left-color 0.25s ease;
    border-left-color: white;
    border-left-style: solid;
    border-left-width: 1px;
}


.remirror-editor.ProseMirror ol { list-style-type: decimal;}
.remirror-editor.ProseMirror ol ol { list-style-type: lower-alpha;}
.remirror-editor.ProseMirror ol ol ol {list-style-type: lower-roman;}
.remirror-editor.ProseMirror ol ol ol ol {list-style-type: decimal;}
.remirror-editor.ProseMirror ol ol ol ol ol {list-style-type: lower-alpha;}
.remirror-editor.ProseMirror ol ol ol ol ol ol {list-style-type: lower-roman;}


.light .remirror-editor.ProseMirror h1, .light .remirror-editor.ProseMirror h2, .light .remirror-editor.ProseMirror h3, .light .remirror-editor.ProseMirror h4, .light .remirror-editor.ProseMirror h5{
    color: black;
    line-height: normal;
    // font-family: $fontFamily;

}
.dark .remirror-editor.ProseMirror h1, .dark .remirror-editor.ProseMirror h2, .dark .remirror-editor.ProseMirror h3, .dark .remirror-editor.ProseMirror h4, .dark .remirror-editor.ProseMirror h5{
    color: white;
    line-height: normal;

    //font-family: $fontFamily;

}

.remirror-theme a{
    cursor: pointer;

}


.ProseMirror div.gigauser-rich-editor-img, img.gigauser-rich-editor-img{
    border: 1px solid lightgrey;
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
    padding: 1px;
    position: relative;
}


.gigauser-text-editor-fix{
    padding-top: 0px;

}


.gigauser-dark{
    .ProseMirror{
        color: #dee2ea;
    }

    .ProseMirror div.gigauser-rich-editor-img, img.gigauser-rich-editor-img{
        border: 1px solid $mediumGrey;
    }

}
