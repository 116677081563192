
@import '../../styles/colors';
@import '../../styles/shadows';

.gigauser-Icon-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    cursor: pointer;
}

.gigauser-Icon{
    // width: 100%;

    svg{
        margin: 0 auto;
    }
}

.HoverTip{
    margin-top: 60px;
    min-width: max-content;
    position: absolute;
    // background-color: transparentize($color: $darkerGrey, $amount: 0.25);
    border-radius: 5px;
    color: white;
    padding: 5px;
    animation-name: popup;
    animation-duration: 0.1s;
    font-size: 0.85em;
    box-shadow: $lighterBoxShadow;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $hoverTipGrey ;

    &.bottom{
        &:before{
            content: ' ';
            height: 0;
            position: absolute;
            width: 0;
            bottom: 20px;
            left: calc(50% - 6px);
            border:8px solid transparent;
            border-right-color: $hoverTipGrey;
            rotate: 90deg;
            pointer-events: none;
        }
    }



    &.top{
        margin-bottom: 128px;
        // margin-left: 24px;

        &:before{
            content: ' ';
            height: 0;
            position: absolute;
            width: 0;
            top: 20px;
            left: calc(50% - 6px);
            border:8px solid transparent;
            border-right-color: $hoverTipGrey;
            rotate: -90deg;
            pointer-events: none;
        }
    }

    .hovertip-text{
        margin-left: 1px;
        font-size: 0.9em;
    }

    .hovertip-shortcut{
        background-color: white;
        border-radius: 3px;
        color: black;
        padding: 3px 6px 3px 5px;
        font-size: 0.85em;
        user-select: none;
        margin: 2px 1px 2px 6px;
    }
}

@keyframes popup {
    0%   {transform: scale(0.1,0.1); padding: 5x;}
    100% {padding: 5x; transform: scale(1,1);}
}